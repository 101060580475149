<div id="main-section-container" class="main-section-container">
  <div class="main-section-wrapper" [ngStyle]="{'opacity': menuOpacity, 'transform': 'scale(' + menuScale + ' )'}">
    
    <div class="title">{{ project.title }}</div>
    <span class="custom-br"></span>
    <div class="header">
      <nav class="navigation">
        <a routerLink="/" routerLinkActive="active" class="link" id="work-link" alt="work">work</a>
        <a routerLink="/about" routerLinkActive="active" class="link" id="about-link" alt="about">about</a>
        <a routerLink="/contact" routerLinkActive="active" class="link" id="contact-link" alt="contact">contact</a>
      </nav>
    </div>
    <div class="arrow">⟶</div>
    
  </div>
</div>
<div class="images-container">
  <div class="images" id="container">
    <li class="item">
      <div class="description">
        <span>Description:</span>
        {{ project.description }}
        <div
          class="model-button"
          (click)="initialiseModel()"
          *ngIf="project.model !== false"
        >View in 3D</div>
      </div>
    </li>
    <li class="item item-colour">
      <img src={{project.titleImage}} class="image"/>
    </li>
    <li *ngFor="let image of project.images" class="item item-colour">
      <img src={{image}} class="image"/>
    </li>
    <li *ngFor="let video of project.videos" class="item item-colour">
      <video autoplay loop [muted]="true" class="image">
        <source src={{video}} type="video/mp4"/>
      </video>
    </li>
    <li class="next-back-buttons item">
      <a class="project-buttons" routerLink={{nextProjectURL}} routerLinkActive="active">
        <div class="next-project-image-container">
          <img src={{nextProject.titleImage}} class="next-image">
        </div>
        <span>Next Project ⟶</span>
      </a>
      <div (click)="backButton()" class="project-buttons">
        ⟵ Back
      </div>
    </li>
  </div>
</div>