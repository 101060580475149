<div class="main-section-container">
  <div class="main-section-wrapper" [ngStyle]="{'opacity': menuOpacity, 'transform': 'scale(' + menuScale + ' )'}">
    <div class="title">{{ MainTitle }}</div>
    <span class="custom-br"></span>
    <div class="header">
      <nav class="navigation">
        <a routerLink="/" routerLinkActive="active" class="link" id="work-link" alt="work">work</a>
        <a routerLink="/about" routerLinkActive="active" class="link" id="about-link" alt="about">about</a>
        <a routerLink="/contact" routerLinkActive="active" class="link" id="contact-link" alt="contact">contact</a>
      </nav>
    </div>
    <div class="arrow">⟶</div>
  </div>
</div>
<div class="projects-container">
  <div class="projects" id="container">
    <li 
      class="item"
      *ngFor="let project of projects"
    >
      <a routerLink={{project.path()}}>
        <div class="image-container">
          <img src={{project.titleImage}} class="images"/>
        </div>
        <div class="project-info">
          <div class="project-title">{{ project.title }}</div>
          <div class="view-button">View ⟶</div>
        </div>
    </a>
    </li>
    <li (click)="backButton()" class="item back-button">
      ⟵ Back
    </li>
  </div>
</div>